import React, { useState } from 'react';
import { Button, Container, TextField, Typography, Card, CardContent } from '@mui/material';

// Mock function to simulate fetching user details
const fetchUserDetails = (userId) => {
    // Simulate an API call to fetch user details based on userId
    return {
        userId: userId,
        username: "JohnDoe",
        status: "Active", // Possible statuses: "Active", "Suspended", etc.
        // Add more details as necessary
    };
};

const UserManagementPage = () => {
    const [userId, setUserId] = useState('');
    const [userDetails, setUserDetails] = useState(null);

    const handleChange = (event) => {
        setUserId(event.target.value);
        setUserDetails(null); // Reset user details when user ID changes
    };

    const handleFetchUserDetails = () => {
        const details = fetchUserDetails(userId);
        setUserDetails(details);
    };

    const handleSuspendUser = () => {
        if (userDetails && userDetails.status === "Suspended") {
            alert("User is already suspended.");
            return;
        }
        console.log(`Suspending user with ID: ${userId}`);
        // Implement the logic to suspend the user here
    };

    // Placeholder for additional user actions
    const handleOtherAction = () => {
        console.log(`Performing other action on user with ID: ${userId}`);
        // Implement additional user action logic here
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ mb: 4 }}>
                User Management
            </Typography>
            <TextField
                label="User ID"
                variant="outlined"
                value={userId}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 2 }}
            />
            <Button
                variant="contained"
                onClick={handleFetchUserDetails}
                sx={{ mb: 2 }}
            >
                Fetch User Details
            </Button>
            {userDetails && (
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">User Details:</Typography>
                        <Typography>Username: {userDetails.username}</Typography>
                        <Typography>Status: {userDetails.status}</Typography>
                        {/* Display more user details here */}
                    </CardContent>
                </Card>
            )}
            <Button
                variant="contained"
                color="secondary"
                onClick={handleSuspendUser}
                sx={{ marginRight: 2 }}
                disabled={!userDetails || userDetails.status === "Suspended"} // Disable if no user details or already suspended
            >
                Suspend User
            </Button>
            <Button
                variant="contained"
                onClick={handleOtherAction}
                disabled={!userDetails} // Disable if no user details
            >
                Other Action
            </Button>
        </Container>
    );
};

export default UserManagementPage;
