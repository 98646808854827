import React, { useEffect, useState } from 'react';
import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

const StakingInstancesPage = () => {
    const [stakingInstances, setStakingInstances] = useState([]);
    const [filter, setFilter] = useState('');

    async function fetchData() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/staking/instances";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })
            console.log(data)
            setStakingInstances(data.staking_instances)
            setStakingInstances(data.staking_instances);




        } catch (e) {
            toast.error("Error fetching Data");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const handleFilterChange = (event) => {
        const value = event.target.value.toLowerCase();
        setFilter(value);
    };

    const filteredInstances = stakingInstances?.filter(instance =>
        instance?.token?.toLowerCase().includes(filter)
    );

    return (
        <Container>
            <Typography variant="h4" sx={{ mb: 2 }}>Staking Instances</Typography>
            <Box sx={{ mb: 2 }}>
                <TextField fullWidth label="Filter by Token" variant="outlined" onChange={handleFilterChange} />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Token</TableCell>
                            <TableCell>Duration (Days)</TableCell>
                            <TableCell>Min Amount</TableCell>
                            <TableCell>Max Amount</TableCell>
                            <TableCell>APY (%)</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Show on UI</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredInstances?.map((instance) => (
                            <TableRow key={instance.id}>
                                <TableCell>{instance.id}</TableCell>
                                <TableCell>{instance.token}</TableCell>
                                <TableCell>{instance.duration}</TableCell>
                                <TableCell>{instance.min_amount}</TableCell>
                                <TableCell>{instance.max_amount}</TableCell>
                                <TableCell>{instance.apy}</TableCell>
                                <TableCell>{instance.is_active ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{instance.show_on_ui ? 'Yes' : 'No'}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default StakingInstancesPage;
