import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from '@mui/material';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import axios from 'axios';

const WithdrawalsPage = () => {
    const [withdrawals, setW] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredWithdrawals, setFilteredWithdrawals] = useState(withdrawals);




    async function fetchData() {
        let setter = setW;
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/all-withdrawals";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })
            console.log(data.withdrawals);
            setW(data.withdrawals);
            setFilteredWithdrawals(data.withdrawals);


        } catch (e) {
            toast.error("Error fetching Data");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);
    const handleSearchChange = (event) => {


        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filteredData = withdrawals.filter(withdrawal =>
            withdrawal.user_id.toLowerCase().includes(value) ||
            withdrawal.currency.toLowerCase().includes(value) ||
            withdrawal.withdrawal_state.toLowerCase().includes(value)
        );
        setFilteredWithdrawals(filteredData);
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ my: 2 }}>Withdrawals</Typography>
            <TextField
                fullWidth
                label="Search Withdrawals"
                variant="outlined"
                onChange={handleSearchChange}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table aria-label="withdrawals table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Network</TableCell>
                            <TableCell>Transaction Hash</TableCell>
                            <TableCell>Block Number</TableCell>
                            <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredWithdrawals.map((withdrawal) => (
                            <TableRow key={withdrawal.id}>
                                <TableCell>{withdrawal.id}</TableCell>
                                <TableCell>{withdrawal.user_id}</TableCell>
                                <TableCell>{withdrawal.amount}</TableCell>
                                <TableCell>{withdrawal.currency}</TableCell>
                                <TableCell>{withdrawal.withdrawal_type}</TableCell>
                                <TableCell>{withdrawal.withdrawal_state}</TableCell>
                                <TableCell>{withdrawal.withdrawal_network}</TableCell>
                                <TableCell>{withdrawal.transaction_hash}</TableCell>
                                <TableCell>{withdrawal.block_number}</TableCell>
                                <TableCell>{withdrawal.withdrawal_timestamp}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default WithdrawalsPage;
