import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, Typography, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BlockIcon from '@mui/icons-material/Block';
import MfaIcon from '@mui/icons-material/EnhancedEncryption';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

// Simplified AccountTypes and MfaMethods for mock purposes
const AccountTypes = { STANDARD: "Standard", PREMIUM: "Premium" };
const MfaMethods = { APP: "App", SMS: "SMS", EMAIL: "Email" };

// Expanded mock user data including more fields from the User struct

const UsersPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);

    async function getUsers() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');


        let route = "/admin/all-users";
        try {

            let { data } = await axios.get(api + route, {
                headers: {
                    'Authorization': 'Bearer ' + password
                }
            });

            console.log(data);
            setFilteredUsers(data.users)
        } catch (e) {
            toast.error("Error fetching users");
        }
    }

    useEffect(() => {
        getUsers();

    }, []);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filteredData = filteredUsers.filter(user =>
            (user.nickname && user.nickname.toLowerCase().includes(value)) ||
            (user.email && user.email.toLowerCase().includes(value))
        );
        setFilteredUsers(filteredData);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ padding: 2 }}>
                <TextField
                    id="search-user"
                    label="Search Users"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                        endAdornment: <SearchIcon />,
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: 640 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>UUID</TableCell>
                            <TableCell>Platform ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email Verified</TableCell>
                            <TableCell>Phone Verified</TableCell>
                            <TableCell>MFA Enabled</TableCell>
                            <TableCell>KYC Level</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => (
                            <TableRow key={user.uuid} hover>
                                <TableCell>{user.uuid}</TableCell>
                                <TableCell>{user.platform_id}</TableCell>
                                <TableCell>{user.email || "null"}</TableCell>
                                <TableCell>{user.phone || "null"}</TableCell>
                                <TableCell>
                                    {user.is_email_verified ? <VerifiedUserIcon color="success" /> : <BlockIcon color="error" />}
                                </TableCell>
                                <TableCell>
                                    {user.is_phone_verified ? <VerifiedUserIcon color="success" /> : <BlockIcon color="error" />}
                                </TableCell>
                                <TableCell>
                                    {user.mfa_enabled ? <MfaIcon color="success" /> : <BlockIcon color="error" />}
                                </TableCell>
                                <TableCell>
                                    <Chip label={`KYC ${user.kyc_level}`} variant="outlined" />
                                </TableCell>
                                <TableCell>{user.is_active ? "Yes" : "No"}</TableCell>
                                <TableCell>
                                    <IconButton color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default UsersPage;
