import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Container } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import Cookies from 'js-cookie';

// Mock data for currencies and network configuration

const CurrencyManagementPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState({});
    let [currencies, setCurrencies] = useState([]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleOpen = (id) => {
        setOpen(prevOpen => ({
            ...prevOpen,
            [id]: !prevOpen[id],
        }));
    };


    async function getCurrencies() {
        let api = Cookies.get('api');
        let route = "/currency/currencies"
        let { data } = await axios.get(api + route);
        let currencies = data.data;
        console.log(currencies);
        setCurrencies(currencies);
    }


    useEffect(() => {
        getCurrencies();
    }, []);

    const filteredCurrencies = currencies.filter(currency =>
        currency.name.toLowerCase().includes(searchTerm) ||
        currency.symbol.toLowerCase().includes(searchTerm) ||
        currency.networks.some(network => network.network_id.toLowerCase().includes(searchTerm))
    );

    return (
        <Container>
            <Typography variant="h4" sx={{ my: 2 }}>Currencies</Typography>
            <TextField
                fullWidth
                label="Search Currencies"
                variant="outlined"
                onChange={handleSearchChange}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Name</TableCell>
                            <TableCell>Symbol</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>UI Priority</TableCell>
                            <TableCell>Show in UI</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCurrencies.map((currency) => (
                            <React.Fragment key={currency.id}>
                                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleOpen(currency.id)}>
                                            {open[currency.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell component="th" scope="row">{currency.name}</TableCell>
                                    <TableCell>{currency.symbol}</TableCell>
                                    <TableCell>{currency.description}</TableCell>
                                    <TableCell>{currency.ui_priority}</TableCell>
                                    <TableCell>{currency.show_in_ui ? "Yes" : "No"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={open[currency.id]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Network Configurations
                                                </Typography>
                                                <Table size="small" aria-label="network-configurations">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Network ID</TableCell>
                                                            <TableCell>Contract Address</TableCell>
                                                            <TableCell>Withdrawal Fee</TableCell>
                                                            <TableCell>Withdraw Fee Currency</TableCell>
                                                            <TableCell>Decimals</TableCell>
                                                            <TableCell>Is Native</TableCell>
                                                            <TableCell>Gas Limit</TableCell>
                                                            <TableCell>Is Withdrawal Active</TableCell>
                                                            <TableCell>Is Deposit Active</TableCell>
                                                            <TableCell>Minimum Deposit Amount</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {currency.networks.map((network) => (
                                                            <TableRow key={network.network_id}>
                                                                <TableCell>{network.network_id}</TableCell>
                                                                <TableCell>{network.contract_address}</TableCell>
                                                                <TableCell>{network.withdrawal_fee}</TableCell>
                                                                <TableCell>{network.withdraw_fee_currency}</TableCell>
                                                                <TableCell>{network.decimals}</TableCell>
                                                                <TableCell>{network.is_native ? "Yes" : "No"}</TableCell>
                                                                <TableCell>{network.gas_limit}</TableCell>
                                                                <TableCell>{network.is_withdrawal_active ? "Yes" : "No"}</TableCell>
                                                                <TableCell>{network.is_deposit_active ? "Yes" : "No"}</TableCell>
                                                                <TableCell>{network.minimum_deposit_amount}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default CurrencyManagementPage;
