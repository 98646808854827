import React, { useEffect, useState } from 'react';
import { TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from '@mui/material';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';


const RewardsPage = () => {
    const [rewards, setRewards] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRewards, setFilteredRewards] = useState(rewards);

    async function fetchData() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/all-rewards";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })


            let dataToSet = data.data;
            setRewards(dataToSet);
            setFilteredRewards(dataToSet);


        } catch (e) {
            toast.error(e.response.data.message);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filteredData = rewards.filter(reward =>
            reward.platform_id.toLowerCase().includes(value)
        );
        setFilteredRewards(filteredData);
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ my: 2 }}>Rewards</Typography>
            <TextField
                fullWidth
                label="Search by Platform ID"
                variant="outlined"
                onChange={handleSearchChange}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table aria-label="rewards table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Platform ID</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Expire Date</TableCell>
                            <TableCell>Is Claimed</TableCell>
                            <TableCell>Claimed Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRewards?.map((reward) => (
                            <TableRow key={reward?.id}>
                                <TableCell>{reward?.platform_id}</TableCell>
                                <TableCell>{reward?.id}</TableCell>
                                <TableCell>{reward?.currency.toUpperCase()}</TableCell>
                                <TableCell>{reward?.amount / Math.pow(10, 8)}</TableCell>
                                <TableCell>{reward?.date}</TableCell>
                                <TableCell>{reward?.expire_date}</TableCell>
                                <TableCell>{reward?.is_claimed ? "Yes" : "No"}</TableCell>
                                <TableCell>{reward?.claimed_date || "N/A"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default RewardsPage;
