import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';

const RewardUserPage = () => {
    const [rewardDetails, setRewardDetails] = useState({
        platform_id: '',
        currency: '',
        amount: '',
        expiry: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRewardDetails({ ...rewardDetails, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Rewarding User with Details: ', rewardDetails);

        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/rewards/create"

        let body = {
            platform_id: rewardDetails.platform_id,
            currency: rewardDetails.currency.toLowerCase().trim(),
            amount: Number(rewardDetails.amount),
            expiry: Number(rewardDetails.expiry)
        }

        try {
            let { data, status } = await axios.post(api + route, body, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            });
            toast.loading("Rewarding User...", {
                position: "top-right",
                autoClose: 3000,
                toastId: "loading"

            });
            if (status == 201) {
                toast.success("User Rewarded Successfully")
                toast.dismiss("loading");

            }

        } catch (e) {
            toast.error(e.response.data.message);
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" sx={{ mb: 4 }}>Reward User</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Platform ID"
                            variant="outlined"
                            name="platform_id"
                            value={rewardDetails.platform_id}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Currency"
                            variant="outlined"
                            name="currency"
                            value={rewardDetails.currency}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Amount"
                            variant="outlined"
                            name="amount"
                            value={rewardDetails.amount}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: <Typography variant="body2" sx={{ mr: 1 }}></Typography>,
                            }}
                            helperText="Amount is in the smallest unit of the currency"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Expiry (in days)"
                            variant="outlined"
                            name="expiry"
                            value={rewardDetails.expiry}
                            onChange={handleChange}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                            Reward User
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default RewardUserPage;
