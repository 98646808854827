import React, { useEffect, useState } from 'react';
import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

// Assuming this mock data is defined outside the component or fetched from an API

const UserStakesPage = () => {
    const [userStakes, setUserStakes] = useState([]);
    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {
        setFilter(event.target.value.toLowerCase());
    };

    async function fetchData() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/all-user-stakes";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })
            console.log(data)
            setUserStakes(data?.user_stakes);
        } catch (e) {
            toast.error("Error fetching Data");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const filteredStakes = filter ? userStakes.filter(stake => stake.user_id.toLowerCase().includes(filter)) : userStakes;

    return (
        <Container>
            <Typography variant="h4" sx={{ mb: 2 }}>User Stakes</Typography>
            <Box sx={{ mb: 2 }}>
                <TextField
                    fullWidth
                    label="Filter by User ID"
                    variant="outlined"
                    onChange={handleFilterChange}
                    value={filter}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Staking Instance ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Last Claimed At</TableCell>
                            <TableCell>Claimed Amount</TableCell>
                            <TableCell>Is Finished</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredStakes.map((stake) => (
                            <TableRow key={stake?.id}>
                                <TableCell>{stake?.id}</TableCell>
                                <TableCell>{stake?.user_id}</TableCell>
                                <TableCell>{stake?.staking_instance_id}</TableCell>
                                <TableCell>{stake?.amount / Math.pow(10, 8)}</TableCell>
                                <TableCell>{stake?.last_claimed_at || "N/A"}</TableCell>
                                <TableCell>{stake?.claimed_amount || "N/A"}</TableCell>
                                <TableCell>{stake?.is_finished ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{stake?.created_at}</TableCell>
                                <TableCell>{stake.updated_at}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default UserStakesPage;
