import React, { useState } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Switch, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddStakingInstancePage = () => {
    const [open, setOpen] = useState(false);
    const [newInstance, setNewInstance] = useState({
        id: '',
        duration: '',
        token: '',
        min_amount: '',
        max_amount: '',
        apy: '',
        is_active: false,
        show_on_ui: false,
        total_staked: '',
        fee: '',
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setNewInstance(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Submitting new staking instance:', newInstance);
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/staking/create";

        let body = {
            id: newInstance.id,
            duration: Number(newInstance.duration),
            token: newInstance.token,
            min_amount: Number(newInstance.min_amount),
            max_amount: Number(newInstance.max_amount),
            apy: Number(newInstance.apy),
            is_active: newInstance.is_active,
            show_on_ui: newInstance.show_on_ui,
            total_staked: newInstance.total_staked,
            fee: Number(newInstance.fee)
        }

        try {
            let { data, status } = await axios.post(api + route, body, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            });


            if (status === 200) {
                console.log(data);
                toast.success("Staking Instance Created");
            }
        } catch (e) {
            console.log(e);
            toast.error(e.response.data.message);
        }
        // Here, you would typically send this data to your server or state management logic
        // setOpen(false); // Close the dialog after submission
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ my: 2 }}>Add New Staking Instance</Typography>
            <Button variant="contained" onClick={handleClickOpen}>
                Open Form
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Staking Instance</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        {/* Add TextField or appropriate input for each newInstance field */}
                        <TextField fullWidth margin="normal" name="id" label="ID" onChange={handleChange} />
                        <TextField fullWidth margin="normal" name="duration" label="Duration (Days)" type="number" onChange={handleChange} />
                        <TextField fullWidth margin="normal" name="token" label="Token" onChange={handleChange} />
                        <TextField fullWidth margin="normal" name="min_amount" label="Min Amount" type="number" onChange={handleChange} />
                        <TextField fullWidth margin="normal" name="max_amount" label="Max Amount" type="number" onChange={handleChange} />
                        <TextField fullWidth margin="normal" name="apy" label="APY (%)" type="text" onChange={handleChange} />
                        <FormControlLabel control={<Switch checked={newInstance.is_active} onChange={handleChange} name="is_active" />} label="Is Active" />
                        <FormControlLabel control={<Switch checked={newInstance.show_on_ui} onChange={handleChange} name="show_on_ui" />} label="Show on UI" />
                        <TextField fullWidth margin="normal" name="total_staked" label="Total Staked" type="number" onChange={handleChange} />
                        <TextField fullWidth margin="normal" name="fee" label="Fee (%)" type="number" onChange={handleChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Container>
    );
};

export default AddStakingInstancePage;
