import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

// market/markets
const TradingPairsPage = () => {
    const [markets, SetMarkets] = useState([]);
    async function fetchData() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/market/markets";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })
            SetMarkets(data.markets)




        } catch (e) {
            toast.error("Error fetching Data");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <TableContainer component={Paper}>
            <Typography variant="h4" sx={{ m: 2 }}>Trading Pairs</Typography>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Symbol</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Base Asset</TableCell>
                        <TableCell>Base Asset Precision</TableCell>
                        <TableCell>Quote Asset</TableCell>
                        <TableCell>Quote Asset Precision</TableCell>
                        <TableCell>Allowed Order Types</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {markets.map((market) => (
                        <TableRow key={market.symbol}>
                            <TableCell component="th" scope="row">{market.symbol}</TableCell>
                            <TableCell>{market.status}</TableCell>
                            <TableCell>{market.base_asset}</TableCell>
                            <TableCell>{market.base_asset_precision}</TableCell>
                            <TableCell>{market.quote_asset}</TableCell>
                            <TableCell>{market.quote_asset_precision}</TableCell>
                            <TableCell>{market.allowed_order_types.join(" , ")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TradingPairsPage;
