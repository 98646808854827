import React, { useState } from 'react';
import { Button, Card, CardContent, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';

// Mock data for SpotOrders including fills
const mockSpotOrders = [
    {
        pair: "BTC/USD",
        side: "Buy",
        timestamp: 1615158000000,
        owner: "user123",
        price: 50000,
        order_id: "order123",
        order_type: "Limit",
        time_in_force: "GTC",
        initial_quantity: 1,
        executed_quantity: 0.5,
        status: "Partially Filled",
        fills: [
            { price: 50000, quantity: 0.25, timestamp: 1615158100000 },
            { price: 50020, quantity: 0.25, timestamp: 1615158200000 }
        ],
    },
    // Add more mock orders as needed
];

const SpotOrdersPage = () => {
    const [filters, setFilters] = useState({
        pair: '',
        side: '',
        order_type: '',
        status: '',
    });
    const [orders, setOrders] = useState([]);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    };

    const fetchOrders = () => {
        // Simulate fetching filtered orders
        setOrders(mockSpotOrders.filter(order =>
            (!filters.pair || order.pair.includes(filters.pair)) &&
            (!filters.side || order.side === filters.side) &&
            (!filters.order_type || order.order_type === filters.order_type) &&
            (!filters.status || order.status === filters.status)
        ));
    };

    return (
        <Container maxWidth="lg">
            <Typography variant="h4" sx={{ my: 4 }}>Spot Orders Management</Typography>
            <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6} lg={3}>
                    <TextField
                        fullWidth
                        label="Trading Pair"
                        name="pair"
                        value={filters.pair}
                        onChange={handleFilterChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6} sm={3} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel>Side</InputLabel>
                        <Select
                            name="side"
                            value={filters.side}
                            onChange={handleFilterChange}
                            label="Side"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Buy">Buy</MenuItem>
                            <MenuItem value="Sell">Sell</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel>Order Type</InputLabel>
                        <Select
                            name="order_type"
                            value={filters.order_type}
                            onChange={handleFilterChange}
                            label="Order Type"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Limit">Limit</MenuItem>
                            <MenuItem value="Market">Market</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            name="status"
                            value={filters.status}
                            onChange={handleFilterChange}
                            label="Status"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Open">Open</MenuItem>
                            <MenuItem value="Partially Filled">Partially Filled</MenuItem>
                            <MenuItem value="Filled">Filled</MenuItem>
                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={fetchOrders}>
                        Fetch Orders
                    </Button>
                </Grid>
            </Grid>
            {orders.map((order, index) => (
                <Card key={index} sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">Order ID: {order.order_id}</Typography>
                        <Typography>Pair: {order.pair}</Typography>
                        <Typography>Side: {order.side}</Typography>
                        <Typography>Order Type: {order.order_type}</Typography>
                        <Typography>Price: {order.price}</Typography>
                        <Typography>Initial Quantity: {order.initial_quantity}</Typography>
                        <Typography>Executed Quantity: {order.executed_quantity}</Typography>
                        <Typography>Status: {order.status}</Typography>
                        <Typography>Time In Force: {order.time_in_force}</Typography>
                        <Typography>Owner: {order.owner}</Typography>
                        <Typography>Timestamp: {moment(order.timestamp).format('LLLL')}</Typography>
                        <Typography>Fills:</Typography>
                        <List dense>
                            {order.fills.map((fill, fillIndex) => (
                                <ListItem key={fillIndex}>
                                    <ListItemText
                                        primary={`Price: ${fill.price}, Quantity: ${fill.quantity}`}
                                        secondary={`Timestamp: ${moment(fill.timestamp).format('LLLL')}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            ))}
        </Container>
    );
};

export default SpotOrdersPage;
