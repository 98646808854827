import React from 'react';
import Header from './header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes, Switch, Link } from 'react-router-dom';
import AdminSignOn from './pages/login';
import UsersPage from './pages/users';
import TradingPairsPage from './pages/markets';
import CreateTradingPairPage from './pages/createMarket';
import TradesPage from './pages/trades';
import CurrencyManagementPage from './pages/currencies';
import DepositsPage from './pages/deposits';
import WithdrawalsPage from './pages/withdrawals';
import RewardsPage from './pages/rewards';
import AddStakingInstancePage from './pages/addStakingInstance';
import StakingInstancesPage from './pages/stakingInstances';
import UserStakesPage from './pages/userStakes';
import UserManagementPage from './pages/userManagement';
import ExchangeManagementPage from './pages/exchangeManager';
import CoinFees from './pages/coinFees';
import SpotOrdersPage from './pages/live_orders';
import RewardUserPage from './pages/rewardUser';

function App() {
  return (
    <>

      <Router>
        <Header />
        <ToastContainer />
        <Routes>

          <Route path="/" element={<AdminSignOn />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/pairs" element={<TradingPairsPage />} />
          <Route path="/new-pair" element={<CreateTradingPairPage />} />
          <Route path="/view-trades" element={<TradesPage />} />
          <Route path="/currencies" element={<CurrencyManagementPage />} />
          <Route path="/deposits" element={<DepositsPage />} />
          <Route path="/withdrawals" element={<WithdrawalsPage />} />
          <Route path="/view-rewards" element={<RewardsPage />} />
          <Route path="/add-staking-instance" element={<AddStakingInstancePage />} />
          <Route path="/view-staking-instance" element={<StakingInstancesPage />} />
          <Route path="/view-user-stakes" element={<UserStakesPage />} />
          <Route path="/manage-user" element={<UserManagementPage />} />
          <Route path="/manager" element={<ExchangeManagementPage />} />
          <Route path="/coin-fees" element={<CoinFees />} />
          <Route path="/live-orders" element={<SpotOrdersPage />} />
          <Route path="/reward-user" element={<RewardUserPage />} />
        </Routes>
      </Router>
    </>
  );
}


export default App;
