import React, { useEffect, useState } from 'react';
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Container } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

const TradesPage = () => {

    // const fills = [
    //     {
    //         trade_id: "1",
    //         pair: "BTC/USDT",
    //         side: "Buy",
    //         price: 50000,
    //         quantity: 1,
    //         timestamp: 1615158000000, // Example timestamp
    //         maker: "MakerAddress1",
    //         taker: "TakerAddress1",
    //         maker_fee: 10,
    //         taker_fee: 15,
    //         buyer: "BuyerAddress1",
    //         seller: "SellerAddress1",
    //     },
    //     {
    //         trade_id: "2",
    //         pair: "ETH/USDT",
    //         side: "Sell",
    //         price: 1500,
    //         quantity: 2,
    //         timestamp: 1615159000000, // Example timestamp
    //         maker: "MakerAddress2",
    //         taker: "TakerAddress2",
    //         maker_fee: 5,
    //         taker_fee: 7,
    //         buyer: "BuyerAddress2",
    //         seller: "SellerAddress2",
    //     },
    //     // Add more mock data as needed
    // ];

    const [fills, setFills] = useState([]);
    const [filteredFills, setFilteredFills] = useState(fills);

    async function fetchData() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/all-trades";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })
            setFills(data.trades)
            setFilteredFills(data.trades);




        } catch (e) {
            toast.error("Error fetching Data");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        const lowercasedValue = value.toLowerCase();
        const filteredData = fills.filter(fill =>
            fill[name].toString().toLowerCase().includes(lowercasedValue)
        );
        setFilteredFills(filteredData);
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ my: 2 }}>Trades</Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                    label="Filter by Pair"
                    variant="outlined"
                    name="pair"
                    onChange={handleFilterChange}
                />
                <TextField
                    label="Filter by Side"
                    variant="outlined"
                    name="side"
                    onChange={handleFilterChange}
                />
                {/* Add more filters as needed */}
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Trade ID</TableCell>
                            <TableCell>Pair</TableCell>
                            <TableCell>Side</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Maker</TableCell>
                            <TableCell>Taker</TableCell>
                            <TableCell>Maker Fee</TableCell>
                            <TableCell>Taker Fee</TableCell>
                            <TableCell>Buyer</TableCell>
                            <TableCell>Seller</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFills.map((fill) => (
                            <TableRow key={fill.trade_id}>
                                <TableCell>{fill.trade_id}</TableCell>
                                <TableCell>{fill.pair}</TableCell>
                                <TableCell>{fill.side}</TableCell>
                                <TableCell>{fill.price / Math.pow(10, 8)}</TableCell>
                                <TableCell>{fill.quantity / Math.pow(10, 8)}</TableCell>
                                <TableCell>{new Date(fill.timestamp).toLocaleString()}</TableCell>
                                <TableCell>{fill.maker}</TableCell>
                                <TableCell>{fill.taker}</TableCell>
                                <TableCell>{fill.maker_fee}</TableCell>
                                <TableCell>{fill.taker_fee}</TableCell>
                                <TableCell>{fill.buyer}</TableCell>
                                <TableCell>{fill.seller}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default TradesPage;
