import React, { useState } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

// Mock data
const initialCoinFees = [
    { currency: "BTC", depositFee: "0.0005", withdrawalFee: "0.0005" },
    { currency: "ETH", depositFee: "0.01", withdrawalFee: "0.01" },
    // Add more currencies as needed
];

const CoinFees = () => {
    const [coinFees, setCoinFees] = useState(initialCoinFees);
    const [editMode, setEditMode] = useState(false);

    const handleChange = (index, field, value) => {
        const updatedCoinFees = [...coinFees];
        updatedCoinFees[index][field] = value;
        setCoinFees(updatedCoinFees);
    };

    const handleSubmit = () => {
        console.log(coinFees);
        // Here, send the updated coinFees to your backend
        setEditMode(false);
    };

    return (
        <Container maxWidth="md">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Currency</TableCell>
                        <TableCell>Deposit Fee</TableCell>
                        <TableCell>Withdrawal Fee</TableCell>
                        {editMode && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {coinFees.map((coin, index) => (
                        <TableRow key={coin.currency}>
                            <TableCell>{coin.currency}</TableCell>
                            <TableCell>
                                {editMode ? (
                                    <TextField
                                        size="small"
                                        value={coin.depositFee}
                                        onChange={(e) => handleChange(index, 'depositFee', e.target.value)}
                                    />
                                ) : (
                                    coin.depositFee
                                )}
                            </TableCell>
                            <TableCell>
                                {editMode ? (
                                    <TextField
                                        size="small"
                                        value={coin.withdrawalFee}
                                        onChange={(e) => handleChange(index, 'withdrawalFee', e.target.value)}
                                    />
                                ) : (
                                    coin.withdrawalFee
                                )}
                            </TableCell>
                            {editMode && (
                                <TableCell>
                                    <IconButton onClick={() => handleSubmit(index)}>
                                        <SaveIcon />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button
                variant="contained"
                color="primary"
                startIcon={editMode ? <SaveIcon /> : <EditIcon />}
                onClick={() => setEditMode(!editMode)}
                sx={{ mt: 2 }}
            >
                {editMode ? 'Submit' : 'Edit'}
            </Button>
        </Container>
    );
};

export default CoinFees;
