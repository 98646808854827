import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Container } from '@mui/material';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';

const CreateTradingPairPage = () => {
    const [tradingPair, setTradingPair] = useState({
        symbol: '',
        status: '',
        base_asset: '',
        base_asset_precision: 8,
        quote_asset: '',
        quote_asset_precision: 8,
        allowed_order_types: ["Market", "Limit"]
    });



    const handleChange = (event) => {
        const { name, value } = event.target;
        setTradingPair(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(tradingPair);

        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/markets/create";

        try {
            let { data, status } = await axios.post(api + route, [tradingPair], {
                headers: {
                    "Authorization": "Bearer " + password
                }
            });

            console.log(status)

            if (status == 200) {
                console.log(data);
                toast.success("Trading Pair Created Possibly");
            }

        } catch (e) {
            toast.error(e.response.data.message);
            toast.error("Error creating trading pair");
        }

        // Here you would typically send this data to your backend or an API to create the trading pair
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" sx={{ mb: 2 }}>Create Trading Pair</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField
                    fullWidth
                    margin="normal"
                    label="Symbol"
                    name="symbol"
                    value={tradingPair.symbol}
                    onChange={handleChange}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select
                        name="status"
                        value={tradingPair.status}
                        label="Status"
                        onChange={handleChange}
                    >
                        <MenuItem value="Trading">Active</MenuItem>
                        <MenuItem value="Suspended">Inactive</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Base Asset"
                    name="base_asset"
                    value={tradingPair.base_asset}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Base Asset Precision"
                    name="base_asset_precision"
                    type="number"
                    value={tradingPair.base_asset_precision}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Quote Asset"
                    name="quote_asset"
                    value={tradingPair.quote_asset}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Quote Asset Precision"
                    name="quote_asset_precision"
                    type="number"
                    value={tradingPair.quote_asset_precision}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label="Allowed Order Types"
                    name="allowed_order_types"
                    value={tradingPair.allowed_order_types}
                    onChange={handleChange}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Create Trading Pair
                </Button>
            </Box>
        </Container>
    );
};

export default CreateTradingPairPage;
