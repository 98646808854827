import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';

const DepositsPage = () => {

    const [searchTerm, setSearchTerm] = useState('');
    let [deposits, setDeposits] = useState([]);
    const [filteredDeposits, setFilteredDeposits] = useState([]);

    async function getDeposits() {
        let api = Cookies.get('api');
        let password = Cookies.get('password');

        let route = "/admin/all-deposits";

        try {
            let { data } = await axios.get(api + route, {
                headers: {
                    "Authorization": "Bearer " + password
                }
            })
            console.log(data);
            setDeposits(data.deposits);
            setFilteredDeposits(data.deposits);
        } catch (e) {
            toast.error("Error fetching deposits");
        }
    }

    useEffect(() => {
        getDeposits();
    }, []);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        if (!value) {
            setFilteredDeposits(deposits);
            return;

        }
        setSearchTerm(value);
        const filteredData = deposits.filter(deposit =>
            deposit.user_id.toLowerCase().includes(value) ||
            deposit.currency.toLowerCase().includes(value) ||
            deposit.deposit_state.toLowerCase().includes(value)
        );
        setFilteredDeposits(filteredData);
    };

    return (
        <Container>
            <Typography variant="h4" sx={{ my: 2 }}>Deposits</Typography>
            <TextField
                fullWidth
                label="Search Deposits"
                variant="outlined"
                onChange={handleSearchChange}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper}>
                <Table aria-label="deposits table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Receive Address</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Network</TableCell>
                            <TableCell>Transaction Hash</TableCell>
                            <TableCell>Block Number</TableCell>
                            <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredDeposits.map((deposit) => (
                            <TableRow key={deposit.id}>
                                <TableCell>{deposit.id}</TableCell>
                                <TableCell>{deposit.user_id}</TableCell>
                                <TableCell>{deposit.amount}</TableCell>
                                <TableCell>{deposit.currency}</TableCell>
                                <TableCell>{deposit.recieve_address}</TableCell>
                                <TableCell>{deposit.deposit_type}</TableCell>
                                <TableCell>{deposit.deposit_state}</TableCell>
                                <TableCell>{deposit.deposit_network}</TableCell>
                                <TableCell>{deposit.transaction_hash}</TableCell>
                                <TableCell>{deposit.block_number}</TableCell>
                                <TableCell>{deposit.deposit_timestamp}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default DepositsPage;
