import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <Container>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Crypto Exchange Admin
                    </Typography>
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose} component={Link} to="/">Admin Sign-On</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/users">Users</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/pairs">Trading Pairs</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/new-pair">Create Trading Pair</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/view-trades">Trades</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/currencies">Currencies</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/deposits">Deposits</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/withdrawals">Withdrawals</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/view-rewards">Rewards</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/add-staking-instance">Add Staking Instance</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/view-staking-instance">View Staking Instances</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/view-user-stakes">User Stakes</MenuItem>

                        <MenuItem onClick={handleClose} component={Link} to="/reward-user">Reward User</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/live-orders">Live Orders</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/manage-user">Manage User</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/manager">Exchange Management</MenuItem>
                        <MenuItem onClick={handleClose} component={Link} to="/coin-fees">Coin Fees</MenuItem>

                    </Menu>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
